<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="wrap bgw coupon">
          <div class="nav">
            <div>
              平台优惠券
              <i></i>
            </div>
          </div>
          <div class="content">
            <div class="list" v-for="(v, i) in couponList" :key="i">
              <div class="top">
                <p>￥{{v.money}}</p>
                <p class="one">满{{parseInt(v.limit)}}可用</p>
                <p>期限：{{ v.start_time}} - {{ v.end_time}}</p>
				
              </div>
              <div class="bottom">
				 <p v-if="v.exist">已领取</p>
                <p @click="GoCoupon(v)" v-else>立即领取</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部
import { usergetinfo } from "@/request/user";
import { getAllCoupons } from "@/request/user";
import { getCoupons } from "@/request/user";
export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      userList: [], // 个人信息
      couponList: [] // 优惠券列表
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.shop_id = localStorage.getItem("lzyyShopId");
    this.init();
    // this.state=this.$route.query.state
  },
  methods: {
    init() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });

      getAllCoupons({
        token: this.$tokens,
        shop_id: this.shop_id
      }).then(res => {
        this.couponList = res.data;
		console.log( this.couponList );
      });
    },
    GoCoupon(v) {
      if (v.exist) {
        this.$message.error({
          type: "success",
          message: "您已领取过了"
        });
        return;
      }

      // 获取优惠券
      getCoupons({
        token: this.$token,
        id: v.id
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "领取成功"
          });
        } else {
          this.$message.error(res.msg)
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  min-height: 600px;
  display: flex;
  padding-top: 36px;
  padding-left: 56px;
  flex-wrap: wrap;
  color: white;
}
.list {
  width: 324px;
  height: 366px;
  margin-bottom: 60px;
  margin-right: 56px;
  text-align: center;
  .top {
    background: #4eeec8;
    padding: 30px;
  }
  .none {
    background: #cccccc;
  }
  .bottom {
    height: 154px;
    background: #f5f5f5;
    padding-top: 56px;
    position: relative;
    cursor: pointer;
    p:nth-of-type(1) {
      color: #4eeec8;
      font-size: 16px;
      width: 110px;
      height: 40px;
      line-height: 40px;
      margin: auto;
      background: rgba(245, 245, 245, 1);
      border: 2px solid rgba(78, 238, 200, 1);
      border-radius: 20px;
    }
    img {
      position: absolute;
      bottom: 20px;
      right: 26px;
      width: 74px;
      height: 74px;
    }
  }
  p:nth-of-type(1) {
    font-size: 36px;
    margin-bottom: 18px;
  }
  p:nth-of-type(2) {
    font-size: 16px;
    margin-bottom: 24px;
  }
  p:nth-of-type(3) {
    font-size: 14px;
  }
}
.nav {
  height: 68px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 18px;
  padding-left: 20px;
  display: flex;
  > div {
    line-height: 68px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    cursor: Pointer;
  }
}
.navActive {
  color: #4eeec8;

  i {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    border-bottom: 2px solid #4eeec8;
  }
}
.coupon {
  border: 1px solid #e6e6e6;
}
</style>